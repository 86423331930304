import { Button, Space } from "antd";
import { PhoneOutlined, SearchOutlined } from "@ant-design/icons";
import Link from "next/link";
import Container from "@/components/Container";
import TopBar from "@/components/TopBar";
import type { IProfessionalDto } from "common/dist/dtos/ProfessionalDto";
import React from "react";
import { Footer } from "./Footer";
import { CardCarousel, ServiceCarousel } from "./CardCarousel";
import * as countries from "i18n-iso-countries";
import { getEndpoints } from "@/api";
import { ICategoryWithParentDto } from "common/dist/dtos/ICategoryDto";
import { IServiceCardDto } from "common/dist/dtos/service/ServiceDto";
import Image from "next/image";
import { HeroDto } from "common/dist/dtos/hero.dto";
import { useMetricsContext } from "@/common/metrics";
import { displayCity, shortName } from "./util";
import { SearchInput } from "./SearchInput";
import { standards, vetting } from "@/pages/[category]/[username]/[slug]";
import { CountryFlag, ProfessionalCard } from "./Professional";
import { PopularTags } from "./PopularTags";
import Head from "next/head";
import { NextPage } from "next";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface IHomepagePageProps {
    popularTalent: IProfessionalDto[];
    hotServices: IServiceCardDto[];
    categories: ICategoryWithParentDto[];
}

const Homepage: NextPage<IHomepagePageProps> = ({ popularTalent, hotServices, categories }) => {
    return (
        <>
            <Head>
                <link rel="canonical" href={process.env.DOMAIN} key="canonical" />
            </Head>
            <TopBar hideSearch />
            <div className="bg-white">
                {/* Header Section */}
                <Header />
                {/* Content Sections */}
                <Container className="space-y-20">
                    <BrowseCategories categories={categories} />
                    <ScaleYourSEO />
                    <Hot hotServices={hotServices} />
                </Container>
                <Container className="space-y-20 mt-20">
                    <Values />
                    <PopularTalent popularTalent={popularTalent} />
                </Container>
                <PowerfulConsulting popularTalent={popularTalent} />
                <Container className="space-y-10 pb-10">
                    <VettingProcess />
                    <JoinSection />
                </Container>
            </div>
            <Footer />
        </>
    );
};

// Header Component
const Header = () => (
    <Container className="block md:grid grid-cols-2 items-start pt-5">
        {/* Left Side */}
        <div>
            {/* Search Input */}
            <div className="md:hidden">
                <SearchInput
                    prefix={<SearchOutlined className="opacity-50" />}
                    size="large"
                    placeholder="Try “niche edits”"
                    enterButton="Search"
                />
            </div>
            {/* Main Title */}
            <h1 className="text-center sm:text-left text-3xl sm:text-4xl mt-8">
                <span className="font-bold">Hire Vetted SEO Experts</span> <br />
                <span className="font-bold"> Maximize Business Growth</span>
            </h1>
            {/* Image (Mobile) */}
            <div className="relative sm:hidden">
                <Image
                    src="https://vettted.blob.core.windows.net/heros/vettted seo freelancer marketplace.png"
                    alt="Hire Vetted SEO experts"
                    className="w-full my-5"
                    layout="responsive"
                    width={1920}
                    height={1080}
                />
            </div>
            {/* Search Input (Desktop) */}
            <div className="hidden mt-5 mb-10 md:grid gap-3 items-center">
                <SearchInput
                    prefix={<SearchOutlined className="opacity-50" />}
                    className="sm:max-w-lg"
                    size="large"
                    placeholder="Try “niche edits”"
                    enterButton="Search"
                />
            </div>
            {/* Popular Tags */}
            <PopularTags />
        </div>
        {/* Right Side */}
        <div className="hidden md:block">
            <div className="h-96 relative">
                <Image
                    src={"https://vettted.blob.core.windows.net/heros/vettted seo freelancer marketplace.png"}
                    layout="fill"
                    objectFit="contain"
                    alt="Hire Vetted SEO experts"
                    className="mx-6 absolute"
                    priority={true}
                />
            </div>
        </div>
    </Container>
);

// BrowseCategories Component
const BrowseCategories = ({ categories }: { categories: ICategoryWithParentDto[] }) => {
    return (
        <div>
            <div className="grid grid-col-1 sm:grid-cols-3 gap-10 mt-5 items-start">
                {categories.map((category) => (
                    <div
                        key={`category-${category.id}`}
                        className="cursor-pointer bg-purple1 text-white rounded-lg shadow-xl  p-8 group bg-gradient-to-b from-purple-500 to-purple-1"
                    >
                        <Link href={`/${category.slug}`}>
                            <div>
                                <h2 className="text-3xl text-center font-bold">{category.name}</h2>
                                <div>{category.description}</div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

// ScaleYourSEO Component
const ScaleYourSEO = () => (
    <div className="text-center">
        {/* Title */}
        <h2 className="text-2xl sm:text-3xl font-bold leading-tight my-10">
            A Vetted and Verified Experience To Scale Your
            <span className="text-purple1"> SEO Strategy</span>
        </h2>
        {/* Description */}
        <div className="sm:text-lg">
            <p>Hire SEO experts to take our online marketing to the next level.</p>
            <p>
                The decision to hire SEO experts is an investment worth making if you want to succeed online. We
                understand scaling a business with confusing SEO strategies on other freelance sites can be complex. We
                get it and this is why Vettted is the first-ever Freelance marketplace built, run, and optimized by SEO
                freelancers.
            </p>
            <p>
                We simplify and solve the challenges of recruiting and vetting all of our freelancers, so we can
                precisely offer you only the absolute best SEO talent. Each of our vetted SEO freelancers has been
                reviewed thoroughly and verified to have proven a track record of success and the technical skills, and
                communication for your online success, so leave it to the experts!
            </p>
        </div>
    </div>
);

// Hot Component
const Hot = ({ hotServices }: { hotServices: IServiceCardDto[] }) => (
    <ServiceCarousel
        header="Popular SEO Services"
        subtitle="Browse and buy curated services from pre-vetted SEO experts"
        services={hotServices}
        id="hot"
    />
);

// PowerfulConsulting Component
const PowerfulConsulting = ({ popularTalent }: { popularTalent: IProfessionalDto[] }) => {
    const consultingTalent = popularTalent.filter((talent) => talent.consultingActive).slice(0, 4);

    return (
        <div className="py-10 my-10">
            <div style={{ backgroundImage: "url('background-irregular.png')", height: "82px" }}></div>
            <div className="bg-blue1 py-10">
                <Container className="grid gap-10 grid-cols-1 lg:grid-cols-2">
                    {/* Description */}
                    <div>
                        <h2 className="text-3xl sm:text-4xl font-bold leading-tight">
                            1-on-1 <span className="text-purple1">Consulting & Strategy Calls</span> with SEO
                            Consultants.
                        </h2>
                        <p className="text-lg sm:text-xl text-grey1">
                            Stop <b>wasting time</b> with inefficient messaging, chats, and endless email back and
                            forth.
                        </p>
                        <p className="text-lg sm:text-xl text-grey1">
                            Vettted gives you the tools to seamlessly hire SEO freelancers, SEO consultants, and digital
                            marketing specialists with video chat features so you can speak with an expert marketer at a
                            click of a button.
                        </p>
                    </div>
                    {/* Talent Avatars */}
                    <Space direction="vertical" align="center" size="large">
                        <div className="p-2 rounded-md grid cursor-pointer grid-cols-1 sm:grid-cols-2 gap-8">
                            {consultingTalent.map((talent) => (
                                <TalentAvatar key={talent.id} talent={talent} />
                            ))}
                        </div>
                        <Link href="/hire-seo-freelancers">
                            <Button className="place-self-center" type="primary" size="large">
                                Browse SEO Professionals
                            </Button>
                        </Link>
                    </Space>
                </Container>
            </div>
        </div>
    );
};

// TalentAvatar Component
export const TalentAvatar = ({ talent }: { talent: IProfessionalDto }) => (
    <Link href={talent.profile} passHref>
        <a className="bg-white border text-black hover:text-black border-gray-200 space-x-3 shadow-xl border-solid rounded-md py-2 px-3 flex cursor-pointer">
            <div className="w-16 h-16">
                <Image src={talent.image} className="rounded-full object-center object-cover" width={64} height={64} />
            </div>
            <div className="mt-0 flex-grow">
                <div className="flex text-2xl mb-0 mt-1 items-center space-x-1">
                    <div className="font-bold text-2xl h-8 overflow-hidden">{shortName(talent.name)}</div>
                    <CountryFlag country={talent.country} scale={0.7} />
                </div>
                <div className="space-x-1 text-xs font-normal mt-2 flex justify-between">
                    <span className="flex justify-items-start text-xs font-normal">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                clipRule="evenodd"
                            />
                        </svg>
                        {talent.city}
                    </span>
                    {talent.consultingActive && (
                        <span className="flex justify-items-start space-x-1">
                            <PhoneOutlined className="text-sm" />
                            <span className="text-xs font-normal">${talent.consultingPrice}</span>
                        </span>
                    )}
                </div>
            </div>
        </a>
    </Link>
);

// VettingProcess Component
const VettingProcess = () => (
    <>
        <div className="text-center">
            {/* Title */}
            <h2 className="text-5xl">
                <span className="inner-underline">How</span>
                <span className="font-extrabold"> Is Our Talent Vettted?</span>
            </h2>
            {/* Description */}
            <div>Only 5% of applications get accepted to Vettted.</div>
        </div>
        <div className="grid gap-10 md:grid-rows-2 md:grid-cols-2">
            {vetting.map((v, i) => (
                <div key={i}>
                    <Space size="large">
                        <Image src={v.icon} alt={v.header} width={64} height={64} className="h-16 w-16" />{" "}
                        <h3 className="font-bold inline-block m-0">{v.header}</h3>
                    </Space>
                    <div className="py-4 whitespace-pre-wrap col-span-2" style={{ fontSize: "20px" }}>
                        {v.description}
                    </div>
                </div>
            ))}
        </div>
    </>
);

// JoinSection Component
const JoinSection = () => (
    <div className="grid md:grid-cols-2 gap-10">
        {/* Start As A SEO Client */}
        <Join
            title="Start As A SEO Client"
            description="Hire vetted SEO specialists and elevate your search engine rankings today."
            href="/signup"
            cta="Get Started"
        />
        {/* Apply As An Expert */}
        <Join
            title="Apply As An Expert"
            description="Join Vettted's exclusive talent network of Search Engine Optimization specialists."
            href="/apply"
            cta="Apply Now"
        />
    </div>
);

export interface IJoinProps {
    title: string;
    description: string;
    cta: string;
    href: string;
}

// Join Component
const Join = ({ title, description, href, cta }: IJoinProps) => (
    <div className="rounded-lg p-10 bg-blue1 text-center">
        <div className="mb-2">
            <h2 className="text-4xl font-sans">{title}</h2>
            <div className="text-gray-500" style={{ fontSize: "20px" }}>
                {description}
            </div>
        </div>
        <Link href={href} passHref>
            <Button size="large" shape="round" type="primary">
                {cta}
            </Button>
        </Link>
    </div>
);

// Values Component
const Values = () => (
    <div>
        <h2 className="text-center text-5xl mb-14">
            <span className="inner-underline">Why</span> <span className="font-extrabold">Vettted?</span>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 justify-center gap-16">
            {standards.map((s, i) => (
                <div key={`value-${i}`}>
                    <Space size="large">
                        <Image src={s.icon} alt={s.header} width={60} height={60} className="mb-5" />
                        <h3 className="text-2xl font-extrabold mb-6">
                            <span className="inner-underline">{s.header}</span>
                        </h3>
                    </Space>
                    <div className="text-grey1 font-normal" style={{ fontSize: "20px" }}>
                        {s.description}
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export interface IHeroBarProps {
    hero: HeroDto;
}

export const HeroBar = ({ hero }: IHeroBarProps) => {
    const metrics = useMetricsContext();
    return (
        <div
            className="bg-white rounded-md shadow-md border-solid border-gray-400 inline-grid grid-cols-3 items-stretch overflow-hidden"
            style={{ gridTemplateColumns: "auto max-content max-content" }}
        >
            <div className="border-r grid items-center justify-center px-6 py-2">
                <div style={{ fontWeight: "bold", fontSize: "1.5em" }}>{shortName(hero.name)}</div>
            </div>
            <div className="border-r grid items-center justify-center px-6 py-2">
                <div className="flex justify-items-center gap-2">
                    <div style={{ fontSize: "1.2em" }}>{displayCity(hero)}</div>
                    <CountryFlag country={hero.country} scale={0.8} />
                </div>
                <p>{hero.category}</p>
            </div>
            <Link href={hero.profile}>
                <div
                    className="bg-purple1 text-white cursor-pointer grid items-center justify-center p-4 py-2 gap-1"
                    onClick={() => metrics.log({ name: "lets_talk", profile_id: `${hero.id}` })}
                >
                    <PhoneOutlined className="text-3xl" />
                    <b>Let's Talk!</b>
                </div>
            </Link>
        </div>
    );
};

// PopularTalent Component
const PopularTalent = (props: { popularTalent: IProfessionalDto[] }) => {
    const c = props.popularTalent as IProfessionalDto[];
    return (
        c.length > 0 && (
            <CardCarousel
                header="Top Rated SEO professionals"
                height="410px"
                keyPrefix="carousel-card"
                items={c}
                subtitle="Hire SEO experts that are hand-picked by Vettted."
                render={(x) => <ProfessionalCard {...x} />}
                id="popular-talent"
            />
        )
    );
};

Homepage["standalone"] = true;
// Adding getInitialProps method
Homepage.getInitialProps = async (context) => {
    const endpoints = getEndpoints(context);
    const [hotServices, popularTalent, categories] = await Promise.all([
        endpoints.service.listHot(10),
        endpoints.professional.listPopular(10),
        endpoints.category.topLevelCategories()
    ]);
    return {
        hotServices,
        popularTalent,
        categories
    };
};
export default Homepage;
