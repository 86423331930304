import styles from "./Professional.module.css";
import Link from "next/link";
import classNames from "classnames";
import { IProfessionalDto } from "common/dist/dtos/ProfessionalDto";
import { PhoneOutlined } from "@ant-design/icons";
import Image from "next/image";
import { useCommonContext } from "./util";
import { Button, Space, Tag } from "antd";

export interface ICountryFlagProps {
    country: string;
    scale?: number;
}

export const CountryFlag = ({ country, scale = 1 }: ICountryFlagProps) => {
    const lower = country.toLowerCase();
    return (
        <div>
            <Image
                style={{ transform: `scale(${scale})` }}
                src={`https://flagcdn.com/64x48/${lower}.png`}
                alt={country}
                width={32}
                height={24}
            />
        </div>
    );
};

export function ProfessionalCard(props: IProfessionalDto) {
    const categories = useCommonContext().categories;
    const categoryName = categories.find((c) => c.id === props.category)?.name || "";
    return (
        <Link href={props.profile} passHref>
            <a
                className={classNames(
                    styles.card,
                    "mb-5",
                    "cursor-pointer",
                    "shadow-lg",
                    "hover:shadow-xl",
                    "relative",
                    "text-black hover:text-black"
                )}
            >
                <Image
                    src={props.image}
                    quality={100}
                    width="217"
                    height="326"
                    className="object-cover object-center"
                    alt={`${props.name} ${categoryName}`}
                />
                <div
                    className="absolute inset-0 grid p-4 text-white"
                    style={{
                        backgroundImage: "linear-gradient(to top,rgba(16,14,37,1.0), rgba(16,14,37,0.0) 60%)"
                    }}
                >
                    <div className="self-start flex items-center text-xs">
                        <div className="flex-1">
                            <Tag color="black">
                                <b>{props.jobs}</b> orders
                            </Tag>
                        </div>
                        <Button
                            type="primary"
                            size="small"
                            className={classNames({ invisible: !props.consultingActive })}
                        >
                            <Link href={`${props.profile}?consulting`}>
                                <Space className="text-white">
                                    <PhoneOutlined />
                                    <b>${props.consultingPrice}</b>
                                </Space>
                            </Link>
                        </Button>
                    </div>
                    <div className="flex place-center self-end">
                        <h2 className="m-0 text-lg flex-1">{props.name}</h2>
                        <CountryFlag country={props.country} scale={0.7} />
                    </div>
                </div>
            </a>
        </Link>
    );
}
