import Link from "next/link";

export const PopularTags = () => {
    const popularTags = [
        { name: "Backlinks", link: "/off-page-seo/backlinks" },
        { name: "Guest Posts", link: "/off-page-seo/guest-posts" },
        { name: "Niche Edits", link: "/off-page-seo/niche-edits" },
        { name: "Content", link: "/on-page-seo/content-writing-optimization" }
    ];

    return (
        <div className="hidden md:flex flex-wrap gap-2 mt-4">
            <span className="text-xl font-bold mr-2 ">Popular:</span>
            {popularTags.map((tag, index) => (
                <Link href={tag.link} key={index}>
                    <a className="ant-btn ant-btn-primary text-white px-4 py-2 rounded-full">{tag.name}</a>
                </Link>
            ))}
        </div>
    );
};
